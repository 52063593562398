// @ts-nocheck
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
export default new Router({
	mode: 'history',
	routes:
	[
		{
			path: '/',
			name: 'login',
			component: () => import('./views/Login.vue'),
			meta: {
				permission: 'public'
			  }
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('./views/dashboard.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/forget',
			name: 'forget',
			component: () => import('./views/Forget.vue'),
			meta: {
				permission: 'public',
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/selectdictionary/:action?/:requestId?',
			name: 'selectdictionary',
			component: () => import('./views/addword/SelectDictionary.vue'),
			meta: {
				permission: 'admin',
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/addword/:dictionary/:action?/:requestId?',
			name: 'addword',
			component: () => import('./views/addword/AddNewWord.vue'),
			meta: {
				permission: 'admin|translator',
				requiresAuth: true,
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/translator/:action?/:requestId?',
			name: 'editwordtranslator',
			component: () => import('./views/addword/EditWordTranslator.vue'),
			meta: {
				permission: 'translator',
				requiresAuth: true,
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/admin/:action?/:requestId?',
			name: 'viewword',
			component: () => import('./views/addword/ViewWordAdmin.vue'),
			meta: {
				permission: 'admin',
				requiresAuth: true,
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/listnewwords/:page',
			name: 'listnewwords',
			component:() => import('./views/addword/ListNewWords.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin|translator',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/listdeletedwords/:page',
			name: 'listdeletedwords',
			component:() => import('./views/deletedwords/ListDeletedWords.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
        {
            path: '/traffictracker',
            name: 'traffictracker',
            component:() => import('./views/traffictool/TrafficTracker.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank',
            name: 'ordbank',
            component:() => import('./views/ordbanksettings/Ordbank.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/sn-map',
            name: 'ordbankSnMap',
            component:() => import('./views/ordbanksettings/OrdbankSettings.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/nno-search-lemma',
            name: 'OrdbankNnoSearchLemma',
            component:() => import('./views/ordbanksettings/OrdbankNnoSearchLemma.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/nno-search-lexin',
            name: 'OrdbankNnoSnSearchLexin',
            component:() => import('./views/ordbanksettings/OrdbankNnoSnSearchLexin.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/nno-search-lexin/:id',
            name: 'OrdbankNnoSnLemmaEdit',
            component:() => import('./views/ordbanksettings/OrdbankNnoSnLemmaEdit.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/sn-map/view/:id',
            name: 'OrdbankNobLemmaView',
            component:() => import('./views/ordbanksettings/OrdbankNobLemmaView.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/nno-search-lemma/view/:id',
            name: 'OrdbankNnoLemmaView',
            component:() => import('./views/ordbanksettings/OrdbankNnoLemmaView.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/sn-list/view/:id',
            name: 'ordbankSnListView',
            component:() => import('./views/ordbanksettings/OrdbankSnListView.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/sn-list/edit/:id',
            name: 'ordbankSnListEdit',
            component:() => import('./views/ordbanksettings/OrdbankSnListEdit.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/sn-list',
            name: 'ordbankSnList',
            component:() => import('./views/ordbanksettings/OrdbankSnList.vue'),
            props:true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/lexin-tilordne-lemma-nob',
            name: 'ordbankTilordneLemma',
            component:() => import('./views/ordbanksettings/OrdbankTilordneLemma.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/lexin-tilordne-lemma-nno',
            name: 'ordbankTilordneLemmaNno',
            component:() => import('./views/ordbanksettings/OrdbankTilordneLemmaNno.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
        {
            path: '/ordbank/filter-relation-status-list',
            name: 'OrdbankSnRelationStatusList',
            component:() => import('./views/ordbanksettings/OrdbankSnRelationStatusList.vue'),
            props: true,
            meta: {
                requiresAuth: true,
                permission: 'admin|translator|editor',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
		{
			path: '/viewdeletedword/:request_id',
			name: 'viewdeletedword',
			component:() => import('./views/deletedwords/ViewDeletedWord.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/listLexinContent/:page',
			name: 'listLexinContent',
			component:() => import('./views/ListLexinContent.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin|translator|editor',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/reset/:token',
			name: 'reset',
			component: () => import('./views/Reset.vue'),
			meta: {
				permission: 'public',
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/userprofile',
			name: 'profile',
			component: () => import('./views/Profile.vue'),
			meta: {
				permission: 'public',
				requiresAuth: true,
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/changepassword',
			name: 'changepassword',
			component: () => import('./views/ChangePassword.vue'),
			meta: {
				permission: 'public',
				requiresAuth: true,
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/2fa',
			name: '2fa',
			component: () => import('./views/2fa.vue'),
			meta: {
				permission: 'public',
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/dictionary',
			name: 'userboard',
			component: () => import('./views/UserBoard.vue'),
			meta: {
				permission: 'admin|translator|editor',
				requiresAuth: true,
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/listusers/:page',
			name: 'listusers',
			component: () => import('./views/users/ListUsers.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/createuser',
			name: 'createuser',
			component: () => import('./views/users/CreateUser.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/edituser/:id',
			name: 'edituser',
			component: () => import('./views/users/EditUser.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/listroles/:page',
			name: 'listroles',
			component: () => import('./views/roles/ListRoles.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/createrole',
			name: 'createrole',
			component: () => import('./views/roles/CreateRole.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/editrole/:id',
			name: 'editrole',
			component: () => import('./views/roles/EditRole.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/viewrole/:id',
			name: 'viewrole',
			component: () => import('./views/roles/ViewRole.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/listpermissions',
			name: 'listpermissions',
			component: () => import('./views/permissions/ListPermission.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/createpermission',
			name: 'createpermission',
			component: () => import('./views/permissions/CreatePermission.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/editpermission/:id',
			name: 'editpermission',
			component: () => import('./views/permissions/EditPermission.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/listeditorroles/:page',
			name: 'list-editor-roles',
			component: () => import('./views/editors/ListEditorRoles.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/createeditorrole',
			name: 'create-editor-role',
			component: () => import('./views/editors/CreateEditorRole.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/editeditorrole/:id',
			name: 'edit-editor-role',
			component: () => import('./views/editors/EditEditorRole.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/vieweditorrole/:id',
			name: 'view-editor-role',
			component: () => import('./views/editors/ViewEditorRole.vue'),
			meta: {
				requiresAuth: true,
				is_admin: true,

				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/viewrequestadmin',
			name: 'view-request-admin',
			component: () => import('./views/translator/ViewRequestAdmin.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/viewapprovedrequest/:request_id',
			name: 'view-approved-request-admin',
			component: () => import('./views/recyclebin/ViewApprovedRequest.vue'),
			meta: {
				requiresAuth: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/viewrequest',
			name: 'view-request',
			component: () => import('./views/requests/ViewRequest.vue'),
			props: true,
			meta: {
				requiresAuth: true,
				permission: 'editor',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/viewrequesttranslator',
			name: 'view-request-translator',
			component: () => import('./views/translator/ViewRequestTranslator.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'translator',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
        {
            path: '/translatorwordbank',
            name: 'TranslatorWordBank',
            component:() => import('./views/translator/translatorWordBank.vue'),
            meta: {
                requiresAuth: true,
                permission: 'admin|translator',
                progress: {
                    func: [
                        {call: 'color', modifier: 'temp', argument: '#ffb000'},
                        {call: 'fail', modifier: 'temp', argument: '#6e0000'},
                        {call: 'location', modifier: 'temp', argument: 'top'},
                        {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
                    ]
                }
            }
        },
		{
			path: '/translatordashboard',
			name: 'translator-dashboard',
			component:() => import('./views/translator/translatordashboard.vue'),
			meta: {
				requiresAuth: true,
				permission: 'admin|translator',
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/editordashboard',
			name: 'editor-dashboard',
			component:() => import('./views/EditorDashboard.vue'),
			meta: {
				requiresAuth: true,
				permission: 'editor',
				progress: {
				  func: [
					{call: 'color', modifier: 'temp', argument: '#ffb000'},
					{call: 'fail', modifier: 'temp', argument: '#6e0000'},
					{call: 'location', modifier: 'temp', argument: 'top'},
					{call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
				  ]
				}
			  }
		},
		{
			path: '/listchangerequests',
			name: 'listchangerequests',
			component:() => import('./views/translator/ListChangeRequest.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin|translator|editor',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/listchangerequestsdeleted/:page',
			name: 'listchangerequestsdeleted',
			component:() => import('./views/recyclebin/ListChangeRequestDeleted.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				  }
			}
		},
		{
			path: '/help',
			name: 'help',
			component: () => import('./views/Help.vue'),
			meta: {
				requiresAuth: true,
				permission: 'public',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				}
			}
		},
		{
			path: '/traffic-config',
			name: 'trafficConfig',
			component: () => import('./views/TrafficToolConfig.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				}
			}
		},
		{
			path: '/logs',
			name: 'logViewer',
			component: () => import('./views/Logs.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				}
			}
		},
		{
			path: '/logsPerDate/:date',
			name: 'LogsPerDate',
			component: () => import('./views/LogsPerDate.vue'),
			props:true,
			meta: {
				requiresAuth: true,
				permission: 'admin',
				progress: {
					func: [
					  {call: 'color', modifier: 'temp', argument: '#ffb000'},
					  {call: 'fail', modifier: 'temp', argument: '#6e0000'},
					  {call: 'location', modifier: 'temp', argument: 'top'},
					  {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 900}}
					]
				}
			}
		},
		{
			path: '*',
			name: 'notfound',
			component: () => import('./views/NotFound.vue'),
			meta: {
				permission: 'public',
			}
		}
	],
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	  }
})
